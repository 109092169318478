import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup'
import { DictExercises, DictQuantityType, DictUnits, NewExercise, LastExerciseData } from '../../../../../types/types';
import ExerciseService from '../../../../../services/exercises';
import ExercisesTrainingService from '../../../../../services/exercises/training/ExercisesTrainingService';
import DictUnitsService from '../../../../../services/dict/DictUnitsService';
import DictQuantityTypeService from '../../../../../services/dict/DictQuantityTypeService';

interface Props {
    workbook_id: number;
    training_id: number;
    onRefresh: () => void;
    t: any;
}

interface State {
    dictExercises: DictExercises[];
    dictUnits: DictUnits[];
    dictQuantityTypes: DictQuantityType[];
    isDataLoaded: boolean;
    lastExerciseData: LastExerciseData | null; // Dodajemy nowy stan
    selectedCategory: string,
    dictExerciseId: string,

}

class AddExercise extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            dictExercises: [],
            dictUnits: [],
            dictQuantityTypes: [],
            isDataLoaded: false, // Nowy stan do śledzenia załadowania
            lastExerciseData: null, // Początkowa wartość
            selectedCategory: '',
            dictExerciseId: '',

        };
    }

    componentDidMount() {
        Promise.all([
            ExerciseService.getDictExercises(),
            DictUnitsService.getDictUnits(),
            DictQuantityTypeService.getDictQuantityTypes()
        ])
            .then(([exercises, units, quantityTypes]) => {
                this.setState({
                    dictExercises: exercises.data,
                    dictUnits: units.data,
                    dictQuantityTypes: quantityTypes.data,
                    isDataLoaded: true
                });
            })
            .catch(error => console.error('Error loading dictionaries:', error));
    }

    getLastExerciseTrainingData = (exerciseId: string, setFieldValue: any) => {
        ExercisesTrainingService.getLastExerciseTrainingData(exerciseId)
            .then(response => {
                console.log("Last exercise data:", response.data);
                const lastExerciseData = response.data;
                this.setState({ lastExerciseData: response.data });

                if (lastExerciseData && lastExerciseData.quantity) {
                    setFieldValue('quantity', lastExerciseData.quantity); // Ustawiamy wartość quantity w formularzu
                    setFieldValue('volume', lastExerciseData.volume);
                }
            })
            .catch(error => {
                console.error("Error fetching last exercise data:", error);
                this.setState({ lastExerciseData: null });
            });
    };

    handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
        const { value } = e.target;
        this.setState({ selectedCategory: value, dictExerciseId: '' });
        setFieldValue('selectedCategory', value);
    };

    handleExerciseChange = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
        const { value } = e.target;
        this.setState({ dictExerciseId: value });

        if (value) {
            this.getLastExerciseTrainingData(value, setFieldValue); // Pobieramy dane po wyborze ćwiczenia
        }
        setFieldValue('dictExerciseId', value);
    };


    postExercise = (exercise: NewExercise) => {
        ExerciseService.postExercise(exercise)
            .then(() => {
                this.props.onRefresh(); // Wywołanie funkcji odświeżania
            })
            .catch(error => console.error('Error posting exercise:', error));
    };

    render() {
        const { t, training_id } = this.props;
        const { dictExercises, dictUnits, dictQuantityTypes, isDataLoaded, selectedCategory, dictExerciseId } = this.state;

        if (!isDataLoaded) return <p><span className='loader' /></p>; // Czekamy, aż dane się załadują

        const initialExercise: NewExercise = {
            id: crypto.randomUUID(),
            trainingId: training_id,
            dictExerciseId: dictExerciseId,
            quantity: 10,
            dictQuantityTypeId: dictQuantityTypes.length > 0 ? dictQuantityTypes[0].id : '',
            volume: 0,
            dictUnitId: dictUnits.length > 0 ? dictUnits[0].id : '',
            notes: ''
        };

        const uniqueCategories = Array.from(
            new Set(dictExercises.map((exercise) => exercise.category_name))
        );

        const validationSchema = Yup.object().shape({
            selectedCategory: Yup.string().required(t('workbooks.select_category')),
            dictExerciseId: Yup.string().required(t('workbooks.select_exercise'))
        })

        return (
            <div>
                <Formik
                    initialValues={{ ...initialExercise, selectedCategory: '' }}
                    validationSchema={validationSchema}
                    onSubmit={this.postExercise}
                >
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form>
                            <div style={{ display: 'grid', gap: '1em', gridTemplateColumns: '1fr 1.5fr' }}>
                                {/* 1. Kategoria (pełny wiersz) */}
                                <div style={{ gridColumn: '1 / -1' }}>
                                    <Field
                                        as="select"
                                        name="selectedCategory"
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleCategoryChange(e, setFieldValue)}
                                        style={{ width: '100%', padding: '0.5em', borderRadius: '6px' }}
                                    >
                                        <option value="" disabled>{t('workbooks.select_category')}</option>
                                        {uniqueCategories.map((category) => (
                                            <option key={category} value={category}>{category}</option>
                                        ))}
                                    </Field>
                                    {errors.selectedCategory && touched.selectedCategory && (
                                        <div className="error">{errors.selectedCategory}</div>
                                    )}
                                </div>

                                {/* 2. Ćwiczenie (pełny wiersz) */}
                                <div style={{ gridColumn: '1 / -1' }}>
                                    <Field as="select"
                                        name="dictExerciseId"
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleExerciseChange(e, setFieldValue)}
                                        style={{ width: '100%', padding: '0.5em', borderRadius: '6px' }}
                                    >
                                        <option value="" disabled>{t('workbooks.select_exercise')}</option>
                                        {dictExercises
                                            .filter((exercise) => exercise.category_name === values.selectedCategory)
                                            .map((exercise) => (
                                                <option key={exercise.id} value={exercise.id}>{exercise.name}</option>
                                            ))}
                                    </Field>
                                    {errors.dictExerciseId && touched.dictExerciseId && (
                                        <div className="error">{errors.dictExerciseId}</div>
                                    )}
                                </div>

                                {/* 3. Ilość + Typ ilości */}
                                <Field name="quantity"
                                    type="number"
                                    style={{ padding: '0.5em', borderRadius: '6px' }}
                                />
                                <Field as="select"
                                    name="dictQuantityTypeId"
                                    style={{ marginLeft: '0.5em', padding: '0.5em', borderRadius: '6px' }}
                                >
                                    {dictQuantityTypes.map(quantity => (
                                        <option key={quantity.id} value={quantity.id}>{quantity.name}</option>
                                    ))}
                                </Field>

                                {/* 4. Objętość + Jednostka */}
                                <Field name="volume"
                                    type="number"
                                    style={{ padding: '0.5em', borderRadius: '6px' }}
                                />
                                <Field as="select"
                                    name="dictUnitId"
                                    style={{ marginLeft: '0.5em', padding: '0.5em', borderRadius: '6px' }}
                                >
                                    {dictUnits.map(unit => (
                                        <option key={unit.id} value={unit.id}>{unit.name}</option>
                                    ))}
                                </Field>

                                {/* 5. Notatki (pełny wiersz) */}
                                <div style={{ gridColumn: '1 / -1' }}>
                                    <Field
                                        name="notes"
                                        type="text"
                                        style={{ width: '100%', padding: '0.5em', borderRadius: '6px' }}
                                        placeholder={t('table.notes')}
                                    />
                                </div>

                                {/* 6. Przycisk (pełny wiersz, wyrównany do prawej) */}
                                <div style={{ gridColumn: '1 / -1', display: 'flex', justifyContent: 'flex-end' }}>
                                    <button type="submit">{t('buttons.add')}</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
        );
    }
}

export default withTranslation('global')(AddExercise);
