import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { VolumeMaxVolumeAvgVolumeForEachExerciseEachTraining } from '../../../types/types';
import DiagramService from "../../../services/diagrams/DiagramService";

interface Props {
    workbookId: number;
}

export const DiagramVolumePerExercise: React.FC<Props> = ({ workbookId }) => {
    const [data, setData] = useState<VolumeMaxVolumeAvgVolumeForEachExerciseEachTraining[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        DiagramService.getVolumeMaxVolumeAvgVolumeForEachExerciseEachTraining(workbookId)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error("Error fetching volume data", err);
                setLoading(false);
            });
    }, [workbookId]);

    if (loading) return <span className="loader" />;

    const categories = Array.from(new Set(data.map((d) => d.category)));

    return (
        <Tabs>
            <TabList>
                {categories.map((category) => (
                    <Tab key={category}>{category}</Tab>
                ))}
            </TabList>

            {categories.map((category) => {
                const exercises = Array.from(new Set(data.filter((d) => d.category === category).map((d) => d.exercise)));

                return (
                    <TabPanel key={category}>
                        {exercises.map((exercise) => {
                            const exerciseData = data
                                .filter((d) => d.exercise === exercise && d.category === category)
                                .sort((a, b) => a.date.localeCompare(b.date));

                            const chartData = [
                                ["Date", "col01", "col02", "col03", "col04", "col05", "volumeAvg", "volumeMax"],
                                ...exerciseData.map((d) => [
                                    d.date,
                                    d.volumeCol01,
                                    d.volumeCol02,
                                    d.volumeCol03,
                                    d.volumeCol04,
                                    d.volumeCol05,
                                    d.volumeAvg,
                                    d.volumeMax,
                                ]),
                            ];

                            const options = {
                                title: `Volume per training: ${exercise}`,
                                hAxis: { title: "Date" },
                                vAxis: { title: "Volume" },
                                seriesType: "bars",
                                series: {
                                    6: { type: "line", color: "#3366CC" }, // volumeAvg
                                    7: { type: "line", color: "#DC3912" }, // volumeMax
                                },
                            };

                            return (
                                <div key={exercise} style={{ marginBottom: "2rem" }}>
                                    <Chart
                                        chartType="ComboChart"
                                        width="100%"
                                        height="400px"
                                        data={chartData}
                                        options={options}
                                    />
                                </div>
                            );
                        })}
                    </TabPanel>
                );
            })}
        </Tabs>
    );
};