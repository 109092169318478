import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import DiagramService from "../../../services/diagrams/DiagramService";
import { DiagramTrainingsFrequency } from '../../../types/types'; 
import { withTranslation } from "react-i18next";


interface Props {
    workbookId: number;
    t: any;
}

const DiagramTrainingFrequency: React.FC<Props> = ({ workbookId, t }) => {
    const [data, setData] = useState<any[][]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        DiagramService.getTrainingsFrequency(workbookId)
            .then((response) => {
                const result: DiagramTrainingsFrequency[] = response.data;

                const chartData: any[][] = [
                    [
                        { type: "date", id: "Date" },
                        { type: "number", id: "Count" },
                    ],
                    ...result.map((entry) => [
                        new Date(entry.trainingDate),
                        entry.count,
                    ]),
                ];

                setData(chartData);
            })
            .catch((error) => {
                console.error("Error loading training frequency data:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const options = {
        title: t("diagram.training_attendance"),
        colorAxis: {
            colors: ["#800080", "#200080"],
        },
        calendar: {
            cellSize: 12,
            yearLabel: {
                fontSize: 12,
                color: '#757575',
                bold: false,
            },
        },
    };

    if (loading) {
        return <span className="loader" />;
    }

    return (
        <div style={{ overflowX: "auto" }}>
            <Chart
                chartType="Calendar"
                width="700px" // lub więcej, ale nie "100%"
                // height="350px"
                data={data}
                options={options}
            />
        </div>
    );
};

export default withTranslation("global")(DiagramTrainingFrequency)
